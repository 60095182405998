import React, {
  createContext,
  FC,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { DirectoryFragment } from '../../../../graphql/generated';
import {
  RecordStateDispatcher,
  useRecordState,
} from '../../../hooks/use-record-state';
import { graphql } from '../../../utils/graphql';

interface DsyncStoreProviderProps {
  initialDirectory?: DirectoryFragment;
}

type DsyncStore = {
  directory: DirectoryFragment;
};

type DsyncStoreContext = DsyncStore & {
  setDsyncStore: RecordStateDispatcher<DsyncStore>;
};

const DsyncContext = createContext<DsyncStoreContext | null>(null);

export const useDsyncStore = () => {
  const context = useContext(DsyncContext);

  if (!context) {
    throw new Error('useDsyncStore can only be used within DsyncContext');
  }

  return context;
};

export const DsyncStoreProvider: FC<Readonly<DsyncStoreProviderProps>> = ({
  children,
  initialDirectory,
}) => {
  const [dsyncStore, setDsyncStore] = useRecordState<DsyncStore>({
    directory: initialDirectory,
  });

  useEffect(() => {
    const loadDirectory = async () => {
      const { data } = await graphql().Directories();

      const [directory] =
        data?.directories.data.filter(
          (directory) => directory.__typename === 'portal_Directory',
        ) ?? [];

      if (directory && directory.__typename === 'portal_Directory') {
        setDsyncStore({
          directory,
        });
      }
    };

    if (!initialDirectory) {
      void loadDirectory();
    }
  }, [initialDirectory, setDsyncStore]);

  const context = useMemo<DsyncStoreContext>(
    () => ({ ...dsyncStore, setDsyncStore }),
    [dsyncStore, setDsyncStore],
  );

  return (
    <DsyncContext.Provider value={context}>{children}</DsyncContext.Provider>
  );
};
