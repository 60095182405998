import React, { VFC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { Topnav } from '../../../components/topnav';
import { DsyncStoreProvider } from '../dsync-store-provider';
import { DsyncRedirector } from './dsync-redirector';

interface DsyncRouterProps {}

export const DsyncRouter: VFC<Readonly<DsyncRouterProps>> = () => (
  <DsyncStoreProvider>
    <Topnav />

    <Routes>
      <Route element={<DsyncRedirector />} path="/">
        <Route element={<div>New Directory</div>} path="/new" />
        <Route element={<div>Configured Directory</div>} path="/:directoryId" />
        <Route element={<div>Directory Steps</div>} path="/configure/*" />
      </Route>
    </Routes>
  </DsyncStoreProvider>
);
