import React, { useEffect, VFC } from 'react';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import { DirectoryState } from '../../../../graphql/generated';
import { useDsyncStore } from '../dsync-store-provider';

export const DsyncRedirector: VFC = () => {
  const navigate = useNavigate();
  const onConfigurePage = useMatch('/dsync/configure/*');

  const { directory } = useDsyncStore();

  useEffect(() => {
    if (directory?.state) {
      if (directory?.state === DirectoryState.Linked) {
        return navigate(`/dsync/${directory.id}`);
      }

      if (!onConfigurePage) {
        return navigate('/dsync/configure/1');
      }
    } else {
      return navigate('/dsync/new');
    }
  }, [directory?.state, directory?.id, onConfigurePage, navigate]);

  return <Outlet />;
};
